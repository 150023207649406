<template>
    <div class="p-2" v-if="store_data">

        <div class="">
            <router-link to="/bussiness" class="h4 mb-0 text-white d-flex align-items-center" style="">
                <i class="bx bx-chevron-left" style="font-size: 20px;color: #FFAD32;"></i>
                Back
            </router-link>
        </div>

        <div class="d-flex mt-1">
            <img :src="store_data.store_picture ? store_data.store_picture : default_img" class="rounded-circle m-auto"
                style="height:100px;width:100px" />
        </div>

        <div class="text-center my-1">
            <h3 class="text-white h4 mb-0">{{ store_data.store_name }}</h3>
            <p class="mt-1 text-white">
                {{ store_data.store_details }}
            </p>
        </div>

        <div class="d-flex">
            <div class="badge bg-danger m-auto" v-if="user.store_data.is_approved == 'n'">
                Waiting for approval
            </div>
        </div>

        <!-- product list -->
        <div class="d-flex align-items-center justify-content-between my-3" style="gap:10px">
            <h4 class="mb-0 text-white font-weight-bolder">Product List</h4>
            <router-link :to="`/bussiness/shop/product`" class="mb-0 h5 text-white font-weight-bolder">Add
                Product</router-link>
        </div>

        <div class="d-flex flex-column" style="gap:10px">
            <div class="d-flex align-items-center" style="gap:10px" v-for="item in product_list">
                <div class="position-relative">
                    <img :src="item.pr_picture"
                        style="height: 80px;width: 80px;background-color: #D9D9D9;border-radius: 10px;object-fit: cover;" />
                </div>
                <div class="flex-fill">
                    <div class="d-flex justify-content-between w-100" style="gap:10px">
                        <h4 class="text-white font-weight-bolder mb-0">{{ item.pr_name }}</h4>
                        <h4 class="text-white font-weight-bolder mb-0">${{ item.pr_price }}</h4>
                    </div>
                    <h6 class="text-white font-weight-normal" style="margin:.5em 0">{{ item.pr_details }}</h6>
                    <div class="d-flex flex-wrap" style="gap:10px">
                        <router-link :to="`/bussiness/shop/product/${item.pr_id}`" style="gap:5px"
                            class="font-weight-bolder d-flex align-items-center">
                            <i class="bx bx-edit" style="font-size: 20px;"></i>
                            <div class="h4 mb-0 text-primary">Edit</div>
                        </router-link>
                        <div @click="delItem(item)" style="gap:5px"
                            class="font-weight-bolder d-flex align-items-center text-primary">
                            <i class="bx bxs-trash" style="font-size: 22px"></i>
                            <div class="h4 mb-0 text-primary">Delete</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- end product list -->

        <!-- <div style="height: 1px;width: 100%;background-color: #FFAD32;" class="my-2"></div> -->

    </div>
</template>

<script>

import store from '@/store'
import Swal from 'sweetalert2'

export default {
    computed: {
        user() {
            return store.state.auth.USER
        },
        store_data() {
            return this.user?.store_data
        },
        product_list() {
            return store.state.store_api.PRODUCT_LIST || []
        }
    },
    methods: {
        delItem(item) {
            Swal.fire({
                title: 'Do you want to delete this product?',
                showCancelButton: true,
                confirmButtonText: 'Yes!',
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch('store_api/DeleteProduct', item.pr_id).then(() => {
                        store.dispatch('store_api/GetProductList', {
                            store_id: this.store_data?.store_id
                        })
                    })
                }
            })
        }
    },
    mounted() {
        store.dispatch('store_api/GetProductList', {
            store_id: this.store_data?.store_id
        })
    },
    data() {
        return {
            default_img: 'https://w7.pngwing.com/pngs/129/292/png-transparent-female-avatar-girl-face-woman-user-flat-classy-users-icon.png'
        }
    }
}

</script>